import React, { useState, useEffect } from "react";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import BlogsView from "../components/BlogsView";
import Pagination from "../components/Pagination";

const Blogs = () => {
  const API_KEY = process.env.REACT_APP_BLOG_API_KEY
  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(6)

  useEffect(() => {
    fetch(`https://www.googleapis.com/blogger/v3/blogs/7286084568129189637/posts?key=${API_KEY}`)
      .then(res => res.json())
      .then(data => {
        setPosts(data.items)
      })
  }, [API_KEY])

  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = (e, pageNumber) => {
    e.preventDefault()
    setCurrentPage(pageNumber)
  }

  return (
    <Layout>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Recent Blogs" />
          <BlogsView blogs={currentPosts} />
          {!(posts.length > postsPerPage) ? null : (
            <Pagination
              className="mt-50"
              itemsPerPage={postsPerPage}
              totalItems={posts.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Blogs
